import * as React from 'react';

// IMPORT STYLES ZONE
import './hobbiesPage.scss';
// END IMPORT STYLES ZONE

// IMPORT LOCALIZE ZONE
import * as localize from './localize.json'
// END IMPORT LOCALIZE ZONE

// IMPORT COMPONENTS ZONE
import Icon from '../../assets/uiComponents/Icon/Icon';
// END IMPORT COMPONENTS ZONE

// IMPORT INTERFACE ZONE
import {
    Language
} from '../../commonInterface';
import Localize from '~Localize';
// END IMPORT INTERFACE ZONE


const HobbiesPage: React.FC = () => {
    return (
        <div id='hobbies' className="swiper-slide">
            <h1 className="animate-me slide-title">
                🎸 <Localize translations={localize.title} />
            </h1>

            <div id="hobbiesCtn">

                <div className="col">
                    <div className="animate-me">
                        <div className="icon">
                            <Icon icon="bicycle" />
                        </div>
                        <h2>
                            <Localize translations={localize.cycling} />
                        </h2>
                    </div>
                    <div className="animate-me">
                        <div className="icon">
                            <Icon icon="plane" />
                        </div>
                        <h2>
                            <Localize translations={localize.travelling} />
                        </h2>
                    </div>
                </div>
                <div className="col">                
                    <div className="animate-me">
                        <div className="icon">
                            <Icon icon="camera" />
                        </div>
                        <h2>
                            <Localize translations={localize.photography} />
                        </h2>
                    </div>
                </div>
                <div className="col">
                    <div className="animate-me">
                        <div className="icon">
                            <Icon icon="cube" />
                        </div>
                        <h2>
                            <Localize translations={localize.printing} />
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HobbiesPage