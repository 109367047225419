import * as React from 'react';
import { Link } from 'react-router-dom';

// IMPORT STYLES ZONE
import './Navbar.scss';
// END IMPORT STYLES ZONE

import { AppContext } from '~contexts/App';

// IMPORT COMPONENTS ZONE
import Icon from '../Icon/Icon';
// END IMPORT COMPONENTS ZONE

// IMPORT LOCALIZE ZONE
import * as localize from './localize.json'
import Localize from '~Localize';
import { useLocalize, Translation } from '~helpers/useLocalize';
// END IMPORT LOCALIZE ZONE

// IMPORT INTERFACE ZONE
type Link = {
    link: string
    translations: Translation
}
// END IMPORT INTERFACE ZONE

// IMPORT IMAGES ZONE
// @ts-ignore
import PtFlagSvg from './imgs/pt.svg';
// @ts-ignore
import UkFlagSvg from './imgs/uk.svg';
// @ts-ignore
import GitlabIcon from '../../img/gitlabIcon.svg';
// END IMPORT IMAGES ZONE

const links: Link[] = [
    {
        link: '/presentation',
        translations: localize.presentation
    },
    {
        link: '/education',
        translations: localize.background
    },
    {
        link: '/skills',
        translations: localize.skills
    },
    {
        link: '/experiences',
        translations: localize.personal_experiences
    },
    {
        link: '/projects',
        translations: localize.projects
    },
    {
        link: '/hobbies',
        translations: localize.hobbies
    },
    {
        link: '/contacts',
        translations: localize.contacts
    }
]

const Navbar: React.FC = () => {
    const { lang, setLang } = React.useContext(AppContext)

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [SOReputation, setSOReputation] = React.useState<number>(0);


    const toggleNavbar = (): void => {
        setIsOpen(!isOpen)
    }

    const languageSelectionRender = (): React.ReactNode => {
        return (
            <div id="languageSelection">

                <PtFlagSvg
                    className={`clickable ${lang === 'pt' ? 'selected' : ''}`}
                    onClick={() => {
                        setLang('pt')
                        setIsOpen(false)
                    }}
                />
                <UkFlagSvg
                    className={`clickable ${lang === 'en' ? 'selected' : ''}`}
                    onClick={() => {
                        setLang('en')
                        setIsOpen(false)
                    }}
                />

            </div>
        )
    }

    const professionalLinksRender = (): React.ReactNode => {
        return (
            <div id="professionalLinks">
                <div className="row">
                    <a
                        href="https://orcid.org/0000-0002-1205-2647"
                        target="_blank"
                        className="clickable"
                    >
                        <Icon prefix="fab" icon="orcid" />
                    </a>
                    <a
                        href="https://www.researchgate.net/profile/Renato-Silva-35"
                        target="_blank"
                        className="clickable"
                    >
                        <Icon prefix="fab" icon="researchgate" />
                    </a>
                    <a
                        href="https://github.com/Renato-Silva"
                        target="_blank"
                        className="clickable"
                    >
                        <Icon prefix="fab" icon="github" />
                    </a>
                    <a
                        href="https://www.linkedin.com/in/renatojmsilva/"
                        target="_blank"
                        className="clickable"
                    >
                        <Icon prefix="fab" icon="linkedin" />
                    </a>
                </div>
            </div>
        )
    }

    const renderLinks = (): JSX.Element => {
        const renderLinksList = () => {
            return links.map(link => {
                return (
                    <li key={link.link}>
                        <Link to={link.link}>
                            <span onClick={() => { setIsOpen(false) }}>
                                {useLocalize(link.translations)}
                            </span>
                        </Link>
                    </li>
                )
            })
        }

        return <ul>{renderLinksList()}</ul>
    }

    return (
        <nav id="navbar" className={isOpen ? "open" : ""}>
            <Icon icon="bars" onClick={() => { toggleNavbar() }} className={isOpen ? "selected burger" : "burger"} />

            <div className="container">
                {renderLinks()}

                {languageSelectionRender()}
                {professionalLinksRender()}
            </div>
        </nav>
    )
}

export default Navbar