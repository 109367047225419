import * as React from 'react';

// IMPORT STYLES ZONE
import './personalExperiencesPage.scss';
// END IMPORT STYLES ZONE

// IMPORT LOCALIZE ZONE
import * as localize from './localize.json'
import { useLocalize } from '~helpers/useLocalize';
// END IMPORT LOCALIZE ZONE

// IMPORT COMPONENTS ZONE
import Icon from '../../assets/uiComponents/Icon/Icon';
// END IMPORT COMPONENTS ZONE

// IMPORT INTERFACE ZONE
import { Language } from '../../commonInterface';
import Localize from '~Localize';
// END IMPORT INTERFACE ZONE


const PersonalExperiencesPage: React.FC = () => {
    return (
        <div id="experiences" className="swiper-slide">
            <h1 className="animate-me slide-title">
                🗂️ <Localize translations={localize.title} />
            </h1>

            <div id="experiencesCtn">

            
                

                <div className="animate-me">
                    <h4>{useLocalize(localize.mysense)}</h4>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: useLocalize(localize.mysense_desc) }}></p>
                    <br />
                    <a className="images-link" href="https://imgur.com/a/7bi80oh" target="_blank">
                        <Icon icon="image" />
                        <p><Localize translations={localize.images} /></p>
                    </a>
                    <hr/> 
                    <p>2020</p>
                </div>

                <div className="animate-me">
                    <h4>{useLocalize(localize.logger)}</h4>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: useLocalize(localize.logger_desc) }}></p>

                    <br />
                    <a className="images-link" href="https://imgur.com/a/ILSzDvD" target="_blank">
                        <Icon icon="image" />
                        <p><Localize translations={localize.images} /></p>
                    </a>
                    <hr/> 
                    <p>2021</p>
                </div>


                <div className="animate-me">
                    <h4>{useLocalize(localize.versatile)}</h4>
                    <br />
                    <a className="images-link" href="https://www.researchgate.net/publication/352878860_A_Versatile_Low-Power_and_Low-Cost_IoT_Device_for_Field_Data_Gathering_in_Precision_Agriculture_Practices" target="_blank">
                        <Icon icon="file-alt" />
                        <p><Localize translations={localize.article} /></p>
                    </a>
                    <hr/> 
                    <p>2021</p>
                </div>

                <div className="animate-me">
                    <h4>{useLocalize(localize.low_cost)}</h4>
                    <br />
                    <a className="images-link" href="https://www.researchgate.net/publication/335518811_Low-Cost_IoT_LoRaRSolutions_for_Precision_Agriculture_Monitoring_Practices" target="_blank">
                        <Icon icon="file-alt" />
                        <p><Localize translations={localize.article} /></p>
                    </a>
                    <hr/> 
                    <p>2019</p>
                </div>

            </div>
        </div>
    )
}

export default PersonalExperiencesPage