import { library } from '@fortawesome/fontawesome-svg-core';
import {
    fab,
    faPhp,
    faPython,
    faHtml5,
    faCss3Alt,
    faReact,
    faAngular,
    faAdobe,
    faGitAlt,
    faGitlab,
    faFontAwesome,
    faLinkedin,
    faGithub,
    faLaravel
} from '@fortawesome/free-brands-svg-icons';

import {
    faGift,
    faListUl,
    faHandshake,
    faPhone,
    faPowerOff,
    faTimesCircle,
    faPaperPlane,
    faUser,
    faLock,
    faKey,
    faTrash,
    faArrowLeft,
    faTag,
    faEnvelope,
    faLightbulb,
    faBolt,
    faCog,
    faHome,
    faBars,
    faLaptop,
    faArrowRight,
    faFileAlt,
    faMusic,
    faFish,
    faSnowboarding,
    faTerminal,
    faFileCode,
    faCircleNotch,
    faBicycle,
    faCamera,
    faCube,
    faPlane,
    faDatabase,
    faMicrochip,
    faWifi,
    faCloud,
    faImage
    
} from '@fortawesome/free-solid-svg-icons';

library.add(
    fab,
    faGift,
    faListUl,
    faHandshake,
    faPhone,
    faPowerOff,
    faTimesCircle,
    faPaperPlane,
    faUser,
    faLock,
    faKey,
    faTrash,
    faArrowLeft,
    faTag,
    faEnvelope,
    faLightbulb,
    faBolt,
    faCog,
    faHome,
    faBars,
    faLaptop,
    faCss3Alt,
    faHtml5,
    faPhp,
    faPython,
    faArrowRight,
    faFileAlt,
    faMusic,
    faFish,
    faSnowboarding,
    faTerminal,
    faReact,
    faAngular,
    faAdobe,
    faGitAlt,
    faGitlab,
    faFileCode,
    faFontAwesome,
    faCircleNotch,
    faLinkedin,
    faBicycle,
    faCamera,
    faCube,
    faPlane,
    faLaravel,
    faDatabase,
    faMicrochip,
    faWifi,
    faCloud,
    faImage
);