import * as React from 'react';

// IMPORT STYLES ZONE
import './projectsPage.scss';
// END IMPORT STYLES ZONE

// IMPORT LOCALIZE ZONE
import * as localize from './localize.json'
import { useLocalize } from '~helpers/useLocalize';
import Localize from '~Localize';
// END IMPORT LOCALIZE ZONE

// IMPORT IMAGES ZONE
//@ts-ignore
import MobileLogo from '../../assets/img/mobile.png';
//@ts-ignore
import WaterLogo from '../../assets/img/water.png';
//@ts-ignore
import MysenseLogo from '../../assets/img/mysense.png';
//@ts-ignore
import KropieLogo from '../../assets/img/kropie.png';
// END IMPORT IMAGES ZONE

// IMPORT COMPONENTS ZONE
import ProjectContainer from './components/projectContainer/projectContainer';
// END IMPORT COMPONENTS ZONE

// IMPORT INTERFACE ZONE
import { Language } from '../../commonInterface';
// END IMPORT INTERFACE ZONE


const ProjectsPage: React.FC = () => {
    return (
        <div id="projects" className="swiper-slide">
            <h1 className="animate-me slide-title">
                🚀 <Localize translations={localize.title} />
            </h1>

            <div className="projects-ctn">
            

                <ProjectContainer
                    link="https://mysenseapi.utad.pt"
                    title="mySense"
                    year={2019}
                    image={MysenseLogo}
                    summary={useLocalize(localize.mysense)}
                    technologiesUsed={['mongodb', 'influxdb', 'laravel', 'microcontrollers']}
                />

                <ProjectContainer
                    link="http://hdl.handle.net/10348/9543"
                    title="Monitoring System"
                    year={2019}
                    image={WaterLogo}
                    summary={useLocalize(localize.water)}
                    linkToGitRepo="http://hdl.handle.net/10348/9543"
                    technologiesUsed={['sensors', 'lora', 'embedded']}
                />

                <ProjectContainer
                    link="#"
                    title="Mobile Positioning"
                    year={2017}
                    image={MobileLogo}
                    summary={useLocalize(localize.mobile)}
                    technologiesUsed={['gnss', 'wireless', 'microcontrollers', 'influxdb']}
                />
                
            </div>
        </div>
    )
}

export default ProjectsPage
