import * as React from 'react';

// IMPORT STYLES ZONE
import './timeLinePage.scss';
// END IMPORT STYLES ZONE

// IMPORT LOCALIZE ZONE
import * as localize from './localize.json'
import Localize from '~Localize';
import { useLocalize } from '~helpers/useLocalize';
// END IMPORT LOCALIZE ZONE

// IMPORT INTERFACE ZONE
import { Language } from '../../commonInterface';
// END IMPORT INTERFACE ZONE

const TimeLinePage: React.FC = () => {
    return (
        <div id="background" className="swiper-slide">
            <h1 className="animate-me slide-title">
                🎓 <Localize translations={localize.title}/>
            </h1>

            <div id="timeline-content">
                <ul className="timeline">
                    
                    <li className="event animate-me animation-goUp">
                        <p className="event-date">2019-2021</p>

                        <h2>
                            <Localize translations={localize.fellow} />
                        </h2>
                        <p><Localize translations={localize.fellow_desc} /></p>

                    </li>
                    <li className="event animate-me animation-goUp">
                        <p className="event-date">2017-2019</p>

                        <h2>
                            <Localize translations={localize.masters} />
                        </h2>
                        <p><Localize translations={localize.masters_desc} /></p>

                    </li>
                    <li className="event animate-me animation-goUp">
                        <p className="event-date">2014-2017</p>

                        <h2>
                            <Localize translations={localize.bachelors} />
                        </h2>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default TimeLinePage;